.navbuttonexp{
  text-decoration: none !important;
}
.product0 {
  margin-top: 5.2rem;
}

#maincontainer-1 {
  padding: 20px !important;
  height: 100vh;
}

#listproduct {
  margin-top: 15px !important;
  width: 350px !important;
}

#productbutton {
  width: 250px !important;
}

/* #bg10 {
  height: 220px;
  width: auto;
  border-radius: 10px;
  background: linear-gradient(90deg, #efd5ff 0%, #515ada 100%);
} */

.kk {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.moto1 {
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 280px;
  background-color: #f8f8f8;
  border-radius: 10px;
  border: 1.5px solid orange;
  box-shadow: 0px 0px 10px rgba(240, 93, 14, 0.2);
  transition: transform 0.3s ease-out;
  gap: 2rem;
  margin-top: 2rem; /* Adjust the margin-top value as needed */
}

.ajay button {
  padding: 8px 5px;
  position: relative;
  display: block;
  border-radius: 8px;
  font-size: smaller;
  margin-top: 20px;
  background: #e8e8e8;
  color: #111111;
}

.ajay button:hover {
  box-shadow: 0 0 5px rgba(237, 132, 76, 0.2) inset, rgba(240, 93, 14, 0.2);
  border: 2px solid orange;
  background-color: orange;
  color: #e8e8e8;
}

.ajay {
  justify-content: center;
  text-align: center;
}

.moto1 h5 {
  font-size: 18px;
}

.moto1:hover {
  box-shadow:0 0 2px 2px 1px orange;

}

.card1 {
}
