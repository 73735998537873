
.row{
    display: flex;
    justify-content: space-around;
}
.product{
    margin-top: 5rem;
}

.product > h6{
    font-size: 1.2rem;
    }

    .ajay
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
@media only screen and (max-width: 576px) {
.product > h1{
font-size: 24px;
}
.product{
    margin-top: 1.5rem;
}
.product > h6{
    font-size: 15px;
    }

.ajay img{
    width: 7rem;
}
.ajay h5{
    font-size: 15px;
}
.moto1{
    height: 16rem;
}
.ajay{
    align-items: center;
    padding: 1.9rem;
 
}
.ajay a{
    font-size: 15px;
}
}