.element{
margin-top: 10px;
/* margin-left: 4rem; */

}
.row.justify-content-center {
  justify-content: center;

}

.product0 {
    margin-top: 5.2rem;
  }
  
  #maincontainer-1 {
    padding: 20px !important;
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    /* display : flex;  */
    height: 100vh;
  }
  
  .logov{
    width: 8rem;
  }
  .card-title{
    margin-top: 1rem;
    font-size: 1.1rem;
  }
  #listproduct {
    margin-top: 15px !important;
    width: 350px !important;
  
    /* margin-top: 10px !important; */
    /* width: 450px !important; */
  }
  
  
  
  #productbutton {
    width: 250px !important;
  }
  

  
  /* my css */
  
  .bg20{
    text-align: center;
    justify-content: center;
    margin-top: 5rem;
  }
  
  .slide1 {
    position: relative;
    overflow: visible;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 280px;
    border-radius: 10px;
    /* border: 1.5px solid orange;
    box-shadow: 0px 0px 10px rgba(240, 93, 14, 0.2);
    transition: transform 0.3s ease-out;
    gap: 2rem; */
    
 
  }

  .my-button{
    border-color: #e8e8e8;
    align-items: center;
    justify-content: center;
text-align: center; 
  }


  
  
   .ajay1 button {
    padding: 8px 5px;
    position: relative;
    display: block;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    font-size:smaller;
    margin-top: 20px;
    background: #e8e8e8;
    color: #111111;
   }
  
   
  

  .ajay1 {
    
    justify-content: center;
    text-align: center;
    
  }
  
  .slide1 h5 {
    font-weight: 100;
    
  }
  
  .moto1:hover {
    box-shadow: 0 0 10px rgba(240, 93, 14, 0.2) inset, 0 0 20px 2px rgba(240, 93, 14, 0.2);
    border: 3px solid rgba(240, 93, 14, 0.2);
  
  
  }

  
  
  .my-button:hover {
    text-decoration: none !important;
  }
 
  .track1 p{
    font-size: 20px;
    margin-top: 12px;
  }

  .track1{
    margin-top: 2rem;
  }
  
  @media only screen and (max-width: 576px) {
    .track1{
      font-size: 22px;
    }
    .track1 p{
      font-size: 12px;
    }
  }