/*==================== 
  Footer 
====================== */

#footer {
  width: 100%;
  height: auto; 
  padding-inline: 1%;
}

/* Main Footer */
footer .main-footer {
  padding: 30px 0 10px 0;
  background: #252525;
  margin-top: 60px;
  border-radius: 5px;
}
.copyright-text{
  color: #000 !important;
  font-size: 0.8rem;
  margin-top: 2px ;
}
footer ul {
  padding-left: 0;
  list-style: none;
}

.copyright{
  background-color: #ff8d1e;
  margin: 0px;
  height: 1.5rem;
}
/* Copy Right Footer */
.footer-copyright {
  background: #ff8d1e;
  
}

.footer-copyright .logo {
  display: inherit;
}

.footer-copyright nav {
  float: right;
  margin-top: 5px;
}

.footer-copyright nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-copyright nav ul li {
  border-left: 1px solid #505050;
  display: inline-block;
  line-height: 12px;
  margin: 0;
  padding: 0 8px;
}

.footer-copyright nav ul li a {
  color: #969696;
}

.footer-copyright nav ul li:first-child {
  border: medium none;
  padding-left: 0;
}

.footer-copyright p {
  color: black;
  margin: 2px 0px;
}

/* Footer Top */
.footer-top {
  background: #252525;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 3px solid #222;
}

/* Footer transparent */
footer.transparent .footer-top,
footer.transparent .main-footer {
  background: transparent;
}

footer.transparent .footer-copyright {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3);
}

/* Footer light */
footer.light .footer-top {
  background: #f9f9f9;
}

footer.light .main-footer {
  background: #f9f9f9;
}

footer.light .footer-copyright {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.3);
}

/* Footer 4 */
.footer- .logo {
  display: inline-block;
}

/*==================== 
  Widgets 
====================== */
.widget {
  padding: 20px;
  margin-bottom: 40px;
}

.widget.widget-last {
  margin-bottom: 0px;
}

.widget.no-box {
  padding: 0;
  background-color: transparent;
  margin-bottom: 40px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
}

.widget.subscribe p {
  margin-bottom: 18px;
}

.widget li a {
  color: #ff8d1e;
}

/* .widget li a:hover{ color: #4b92dc;} */
.widget-title {
  margin-bottom: 20px;
}

.widget-title span {
  background: #839FAD none repeat scroll 0 0;
  display: block;
  height: 1px;
  margin-top: 25px;
  position: relative;
  width: 20%;
}

.widget-title span::after {
  background: inherit;
  content: "";
  height: inherit;
  position: absolute;
  top: -4px;
  width: 50%;
}

.widget-title.text-center span,
.widget-title.text-center span::after {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.widget .badge {
  float: right;
  background: #7f7f7f;
}

.typo-light h1,
.typo-light h2,
.typo-light h3,
.typo-light h4,
.typo-light h5,
.typo-light h6,
.typo-light p,
.typo-light div,
.typo-light span,
.typo-light small {
  color: #fff;
}

ul.social-footer2 {
  margin: 0;
  padding: 0;
  width: auto;
}

ul.social-footer2 li {
  display: inline-block;
  padding: 0;
}

ul.social-footer2 li a:hover {
  background-color: #ff8d1e;
}

ul.social-footer2 li a {
  display: block;
  height: 30px;
  width: 30px;
  text-align: center;
}

.btn {
  background-color: #ff8d1e;
  color: #fff;
}

.btn:hover,
.btn:focus,
.btn.active {
  color: #fff;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 250ms ease-in-out 0s;
  -moz-transition: all 250ms ease-in-out 0s;
  -ms-transition: all 250ms ease-in-out 0s;
  -o-transition: all 250ms ease-in-out 0s;
  transition: all 250ms ease-in-out 0s;
}

.element1 {
  display: flex;
  gap: 10px;
}

.site_map,
img.partner {
  width: 100%;
}

div {
  display: block;
}

#section8,
#section9,
ul.site_map_col {
  float: left;
  width: 100%;
}

p.site_map_title {
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  float: left;
  padding-top: 30px;
  padding-right: 10px;
  margin: 0;
  padding-bottom: 20px;
  text-transform: uppercase;
}

hr {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
}

.thumbnail-widget {
  display: flex;
  flex-direction: column-reverse;
  /* Display the items in reverse order */
  text-align: start;
  align-items: start;
  margin-left: 10%;
}

.thumbnail-widget li {
  margin-bottom: 10px;
  /* Add some space between each item */
}

.widget-title {
  align-items: start;
  margin-left: 11%;
}

.thumb-content {
  text-align: start;
  align-items: start;
}

/* Responsive Styles */



.thumb-content {
  color: orange !important;
}


@media (max-width: 767px) {
  /* Styles for small devices (e.g., mobile phones) */
  /* Modify the layout and styling as per your requirements */
  
  footer .row {
    flex-direction: column;
  }

  .widget {
    margin-bottom: 20px;
  }

  .widget-title span {
    margin: 10px auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Styles for medium devices (e.g., tablets) */
  /* Modify the layout and styling as per your requirements */
  
  footer .row {
    flex-wrap: wrap;
  }

  .col-xs-12,
  .col-sm-6,
  .col-md-3 {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  
  footer .row {
    flex-wrap: wrap;
  }

  .col-xs-12,
  .col-sm-6,
  .col-md-3 {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
}

.social-icons .icon:hover{
  color:#ff8d1e;
}


@media only screen and (max-width: 576px) {
  .privacy{
    font-size: 6px;
  }
}









