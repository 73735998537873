.worldwild{
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
}

.worldwild1 h6{
    padding-top: 3%;
    
}

.worldwild2{
    margin-top: 5%;
}
.worldwild2 h3{
    color: orange;
}

.tabhelp {
    margin-top: 8%;
}

.worldwild2 p{
    margin-top: 2.3%;
    margin-left: 4%;
    margin-right: 4%;
}

.worldwild3{
    margin-top: 5%;
}

.worldwild3 h3{
    margin-top: 2.3%;
    color: orange;
}

.worldwild3 p{
    margin-top: 2.3%;
    margin-left: 4%;
    margin-right: 4%; 
}

.slide1 {
    align-items: center;
    text-align: center;
    justify-content: center;
}