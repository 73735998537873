.container{
  display:contents;
  position: fixed;
  

}

.bg0 {
  
  height: 30px;
  margin-top: 5.5rem;
  width: 100%;
  border-radius: 10px;

  
  
}

.navbutton1{
  color: black;
  margin-inline-start: 25px;
}



.AB-text0{
  width: auto;
  height: auto;
  text-align: center;
  position: absolute;
  top:16rem;
  left:2rem;
  font-family: 'Montserrat', sans-serif;
  justify-content: center;

}

.AB-text0 h1 {
  font-size: 45px;
  color: black;
  position: relative;
  text-align: left !important;

}
.AB-text0 h6 {
  position: absolute;
  left:0rem;
  font-size: 16px;
  color: rgb(255, 255, 255);



}
.AB-text0 p{
  font-weight: bold;
  text-transform: inherit;
 
}

.bg32{
  margin-top: 6.5%;
}

.b11{
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5rem;
}

.paragraph1{
 font-size: 16px;
 text-align: justify;
  text-decoration: transparent;
}

