.hrtag{
  margin-top: 3rem;
}

@media only screen and (max-width: 576px) {
.header1 h1{
  font-size: 22px;
}
.header1 p{
  font-size: 14px;
}
.track h3{
  font-size: 22px;
}

.hide-on-mobile img{
  display: none;
}

}