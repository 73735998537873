.cardCore{
  /* border: 1px solid gray; */
  padding: 15px;
  border-radius: 40px;

}
.bg32{
  font-family: 'Montserrat', sans-serif;

}

.cardCore p{
  font-size: 0.9rem;
}
.meaning-card{
  height: 15rem;
  transition: transform 330ms ease-in-out;
}

.meaning-card h3{
  font-size: 1.4rem;
}

.meaning-card p{
  font-size: 0.9rem;
}

.meaning-card:hover{
  transform: scale(1.05);
}

.coreCard h6{
  color: rgb(255,102,0);
}
.aboutimg{
  height:34rem;
  width:100%;
  margin-top:5.5rem;
}


.paragraph1 img{
  height: 20rem;
  width: 80%;
}
.meaning-card{
  border-radius: 1rem;
  box-shadow: 1px 1px 9px 1px ;
}
.meaning h3{
  color: navy;
}
@media only screen and (max-width: 576px) {
  .aboutimg{
    height:15rem;
    width:100%;
    margin-top:3.5rem;
  }
  .about-containt{
    padding: 0px 2rem;
  }
.paragraph1{
  display: flex;
}
  .about-containt p{
    font-size: 12px;
  }

  .paragraph1 p{
    font-size: 12px;
  }
  .meaning h1,h2, .Ethics h1{
    font-size: 22px;
  }
  .meaning h3{
    font-size: 20px;
    margin-bottom: 1.5rem;
    color: navy;
  }

  .EthicsCard img{
    width: 10rem;
 
  }
  .EthicsCard{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .encompass h1{
    font-size: 22px;
    color: rgb(255,102,0);
  }

.about-containt h3{
font-size: 18px;
}
.odr{
  display: block;
}
.odr img{
  order: 1;
}
.odr h3, .order p{
  order: 2;
}

.paragraph1 img{
  height: 16rem;
  width: 70%;
}
.paragraph1 h3{
  font-size: 18px;
}
}

ol{
  list-style-image: url('../logo.svg');
}

ol > li::marker{
  font-size: 1.5rem;
} 
.aboutimg1 {
  height:100%;
  width:100%;
  margin-top:5.5rem;
  padding: 0rem 10rem;

}
