:root {
  --primary-color: #ff8d1e;
  --font-main: 'Montserrat', sans-serif;
  --hover-fade-time: 0.3s;
}

* {
  font-family: var(--font-main);
}

.navbar {
  background-color: white;
  font-weight: bold;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

.navbar a.nav-link:hover,
.active {
  color: var(--primary-color);
}

.navbar-collapse {
  border-bottom: 3px solid var(--primary-color);
  padding: 1.2rem 3%;
}

.navbar a {
  font-size: 1rem;
  text-transform: capitalize;
}

@media all and (min-width: 992px) {
  .navbar .nav-item:hover .dropdown-menu {
      background-color: #ffffff;
      transition: var(--hover-fade-time);
      opacity: 1;
      visibility: visible;
      top: 100%;
      transform: rotateX(0deg);
  }
}

.dropdown-item.active, 
.dropdown-item:active {
  color: #fff;
  background-color: var(--primary-color);
}

.dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}

.dropdown-menu li {
  position: relative;
}

.navbar-brand .beforelogo {
  width: 2rem;
}

.navbar-brand .logo {
  width: 3.3rem;
  margin-left: 0.8rem;
}

.nav-link {
  margin-left: 1rem;
}

.navbar-light .navbar-toggler-icon {
  background-image: url('../Component/imgs/menu.png');
}

@media only screen and (max-width: 576px) {
  .navbar-brand .beforelogo {
      margin: 1.4rem 0;
      width: 1.5rem;
  }
  .navbar-brand .logo {
      width: 2.4rem;
  }
}

/* ... (rest of the CSS) ... */

/* Dropdown Styling */
.dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1); /* subtle shadow for depth */
  transform: translateY(-20px);  /* starting position for the slide-in effect */
  opacity: 0;  /* make it invisible initially */
  transition: opacity 0.3s, transform 0.3s;  /* fade and slide animation */
  visibility: hidden; /* hide it initially */
}

.navbar .nav-item:hover .dropdown-menu {
  opacity: 1;  /* make it visible on hover */
  transform: translateY(0);  /* end position for the slide-in effect */
  visibility: visible;  /* display it on hover */
}

.dropdown-item {
  padding: 10px 20px;  /* give some padding */
}

.dropdown-item:hover {
  background-color: rgba(255, 141, 30, 0.1);  /* subtle hover effect */
}
