.slides img{
  height: 85vh;
}
.slides{
  margin-top: 5.5rem;
}
@media only screen and (max-width: 576px) {
  .slides img{
     height: 26vh;
  }
  .slides{
    margin-top: 4.5rem;
  }
 }
 
 /* Add fade animation to carousel */
